import { useState, useEffect } from "react";
import { AuthService } from "../services/AuthService";

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    AuthService.subscribe(setIsLoggedIn);
    return () => AuthService.unsubscribe(setIsLoggedIn);
  }, []);

  return isLoggedIn;
};

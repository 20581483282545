import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import style from "../../styles/controls.module.css";

export const ButtonMain = ({
  title = "",
  variant = "filled-green",
  small = false,
  width = 240,
  handle = () => console.warn("need handler"),
  disabled,
  loading = false,
}) => (
  <StyledEngineProvider injectFirst>
    <LoadingButton
      className={`${style.btn} ${style[variant]} ${small ? style.small : ""}`}
      style={{ width }}
      onClick={handle}
      disabled={disabled}
      loading={loading}
      loadingPosition="end"
      endIcon={<></>}
      loadingIndicator={<CircularProgress color="inherit" size={16} />}
    >
      {title}
    </LoadingButton>
  </StyledEngineProvider>
);

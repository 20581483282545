export const formatDate = (date, div=".") => {
  if (!date) return;
  date = new Date(date);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${String(day).padStart(2, "0")}${div}${String(month).padStart(
    2,
    "0"
  )}${div}${year}`;
};

export const getMonthAgo = (value)=> {
  const date = new Date(value);
  const div = 1000*60*60*24*30.5;
  const timestamp = date.getTime();
  return Math.floor((Date.now() - timestamp) / div);
}

export const parseMoneyString = (val) => {
  const num = parseFloat(val.substring(1).replace(/,/g, ""));
  return num || 0;
};

import React, { useState } from "react";
import { DataService } from "../services/DataService";
import { AuthService } from "../services/AuthService";
import Stack from "@mui/material/Stack";
import {
  Filters,
  UsersList,
  UserDetails,
  NewUserDetails,
  ImportUsers,
} from "./";
import { ButtonLogout, ButtonMain } from "./UI";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import logo from "../static/logo-alende.svg";
import "../styles/App.css";

export const Main = () => {
  const queryClient = useQueryClient();
  const {
    data: users,
    isLoading: isUserLoading,
    isRefetching: isUserRefreshing,
  } = useQuery({
    queryKey: ["users"],
    queryFn: DataService.getAllUsers,
    refetchOnWindowFocus: false,
  });

  const { mutate: importUser, isLoading: isImportProcessing } = useMutation({
    mutationFn: DataService.uploadFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(null);
  const [isNewUserOpen, setIsNewUserOpen] = useState(false);

  const openNewUser = () => setIsNewUserOpen(true);

  const onLogout = () => {
    AuthService.logout();
    queryClient.clear();
  };

  return (
    <div className="main-container">
      <header className="main-header">
        <img src={logo} alt="Alende logo" />
        <h1 className="main-header__title">Admin Panel</h1>
        <ButtonLogout handle={onLogout} />
      </header>

      <div className="main-toolpanel">
        <h2>Users List</h2>
        <Stack spacing={2} direction="row">
          <ButtonMain title="Add New User" handle={openNewUser} />
          <ImportUsers handle={importUser} />
          <ButtonMain
            title="Download"
            variant="outlined-green"
            width={112}
            handle={DataService.downloadUsers}
          />
        </Stack>

        <Filters
          open={isFiltersOpen}
          forceOpen={() => setIsFiltersOpen(true)}
          onClose={() => setIsFiltersOpen(false)}
        />
      </div>

      <UsersList
        openUserDetails={(userData) => setIsUserOpen(userData)}
        value={users?.data?.data}
        total={users?.data?.meta?.total}
        pageSize={users?.data?.meta?.per_page}
        selectId={isUserOpen?.id}
        loading={isUserLoading || isUserRefreshing || isImportProcessing}
      />

      {!!isUserOpen && (
        <UserDetails onClose={() => setIsUserOpen(null)} user={isUserOpen} />
      )}

      <NewUserDetails
        open={!!isNewUserOpen}
        onClose={() => setIsNewUserOpen(false)}
      />
    </div>
  );
};

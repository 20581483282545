import "./styles/App.css";
import { Login } from "./components/Login";
import { useAuth } from "./hooks/useAuth";
import { Main } from "./components/Main";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        alert(`Something went wrong: ${error.response?.data?.message}`);
      },
    },
    mutations: {
      onError: (error) => {
        alert(`Something went wrong: ${error.response?.data?.message}`);
      },
    },
  },
});

function App() {
  const isLoggedIn = useAuth();

  return (
    <div className="App">
      {isLoggedIn ? (
        <QueryClientProvider client={queryClient}>
          <Main />
        </QueryClientProvider>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;

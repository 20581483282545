import React, { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CircleFlag } from "react-circle-flags";
import { formatDate, parseMoneyString } from "../utils/formats";
import { DataService } from "../services/DataService";
import { useQueryClient } from "@tanstack/react-query";
import style from "../styles/table.module.css";

export const UsersList = ({
  openUserDetails,
  value = [],
  total,
  pageSize,
  selectId,
  loading,
}) => {
  const queryClient = useQueryClient();

  const users = useMemo(
    () =>
      value.map((user) => {
        if (user.phone_number === 0 || user.phone_number === null)
          user.phone_number = "";
        return user;
      }),
    [value]
  );

  return (
    <div className={style.table}>
      <DataGrid
        onRowClick={(e) => {
          openUserDetails(e.row);
        }}
        selectionModel={[selectId]}
        rows={users}
        loading={loading}
        columns={columns}
        experimentalFeatures={{ newEditingApi: true }}
        paginationMode="server"
        rowCount={total || 0}
        onPageChange={(value) => {
          DataService.setSelection({ page: value + 1 });
          queryClient.invalidateQueries({ queryKey: ["users"] });
        }}
        pagination
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
      />
    </div>
  );
};

const columns = [
  {
    field: "id",
    headerName: "Client #",
    editable: false,
    flex: 5,
    disableColumnMenu: true,
    sortable: false,
    resizable: false,
  },
  {
    field: "last_name",
    headerName: "Last Name",
    editable: false,
    flex: 8,
    disableColumnMenu: true,
    sortable: false,
    resizable: false,
  },
  {
    field: "first_name",
    headerName: "First Name",
    editable: false,
    flex: 8,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "country",
    headerName: "Country",
    editable: false,
    renderCell: (params) => (
      <>
        <CircleFlag
          countryCode={params.value?.code?.toLowerCase()}
          height="22"
        />
        <span style={{ paddingLeft: 8 }}>{params.value.name}</span>
      </>
    ),
    flex: 10,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    editable: false,
    flex: 10,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "client_since",
    headerName: "Client Since",
    editable: false,
    flex: 10,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => formatDate(params?.row?.client_since),
  },
  {
    field: "loans_amount",
    headerName: "Loan Amount",
    editable: false,
    flex: 7,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ row }) => {
      const sum = row.loans.reduce(
        (prev, current) => parseMoneyString(current.amount) + prev,
        0
      );

      return `€ ${sum.toLocaleString("en-US")}`;
    },
  },
];

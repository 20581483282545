import TokenService from "./TokenService";
import api from "./api";

let isAuth = !!TokenService.getLocalAccessToken();
let subscribers = [];

const notify = () => {
  subscribers.forEach((callback) => callback(isAuth));
};

const subscribe = (callback) => {
  subscribers.push(callback);
  notify(isAuth);
};

const unsubscribe = (callback) => {
  subscribers = subscribers.filter((subscriber) => callback === subscriber);
};

const logout = () => {
  TokenService.removeLocalAccessToken();
  isAuth = false;
  notify(isAuth);
};

const login = async (data) => {
  await createSanctumToken();
  const response = await api({
    method: "POST",
    url: "api/v1/auth/login",
    data: JSON.stringify(data),
  });

  TokenService.setLocalAccessToken(response?.data?.token);
  isAuth = true;
  notify(isAuth);

  return response;
};

const createSanctumToken = () => {
  return api({
    method: "GET",
    url: "/sanctum/csrf-cookie",
  });
};

export const AuthService = {
  createSanctumToken,
  login,
  logout,
  unsubscribe,
  subscribe,
};

import React, { useRef } from "react";
import { ButtonMain } from "./UI";

export const ImportUsers = ({ handle }) => {
  const uploadInputRef = useRef(null);

  const uploadUsers = ({ target }) => {
    const file = target.files[0];
    if (file) {
      const body = new FormData();
      body.append("file", file);
      handle(body);
      uploadInputRef.current.value = "";
    }
  };

  return (
    <>
      <input
        ref={uploadInputRef}
        type="file"
        accept=".xlsx"
        style={{ display: "none" }}
        onChange={uploadUsers}
      />
      <ButtonMain
        title="Load New Excel Sheet"
        handle={() => uploadInputRef.current && uploadInputRef.current.click()}
      />
    </>
  );
};

import { ButtonMain } from "./ButtonMain";
import { IconButton } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import style from "../../styles/controls.module.css";

export const ButtonFilter = ({ value = 0, handle, onClear }) => {
  return (
    <div style={{ position: "relative" }}>
      <ButtonMain
        title="Filter"
        width={156}
        variant={!!value ? "outlined-green" : "outlined-black"}
        handle={handle}
      />

      {!!value && (
        <>
          <div className={style["filter-btn__chips"]}>{value}</div>
          <div className={style["filter-btn__close"]}>
            <StyledEngineProvider injectFirst>
              <IconButton onClick={onClear} />
            </StyledEngineProvider>
          </div>
        </>
      )}
    </div>
  );
};

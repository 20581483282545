import { SelectCountry } from "./SelectCountry";

export const CountryFilter = ({ handle, countriesValue }) => (
  <>
    {!!countriesValue.length &&
      countriesValue.map((_, index) => (
        <SelectCountry
          key={`input-${index}`}
          value={countriesValue[index]}
          handle={(value) => {
            const current = [...countriesValue];
            if (value) {
              current[index] = value;
              handle(current);
            } else {
              current.splice(index, 1);
              handle(current);
            }
          }}
        />
      ))}

    <SelectCountry
      key="input-empty"
      value={null}
      handle={(value) => {
        const current = [...countriesValue];
        current[current.length] = value;
        handle(current);
      }}
    />
  </>
);

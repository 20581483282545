import React from "react";
import { TextField, Autocomplete, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledEngineProvider } from "@mui/material/styles";
import styles from "../../styles/controls.module.css";

const statusOptions = ["Active", "Pending", "Default", "Repaid"];

export const SelectStatus = ({ style, handle, value, error }) => {
  const renderInput = (params) => (
    <div style={{ position: "relative", height: "100%" }}>
      <TextField
        {...params}
        name="status"
        error={error}
        variant="filled"
        label="Select status"
        inputProps={{
          ...params.inputProps,
          autoComplete: "new-password",
        }}
      />
    </div>
  );

  return (
    <StyledEngineProvider injectFirst>
      <Autocomplete
        disablePortal
        isOptionEqualToValue={(option, value) => option === value}
        blurOnSelect={true}
        className={styles.input}
        style={style || { width: "100%", marginBottom: 20 }}
        onChange={(e, val) => handle(val)}
        value={value}
        options={statusOptions}
        autoHighlight
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <Box
            value={option.name}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <span className={styles["input__select-item"]}>{option}</span>
          </Box>
        )}
        renderInput={renderInput}
      />
    </StyledEngineProvider>
  );
};

export const ControlledSelectStatus = ({ control, error, rules = {} }) => (
  <Controller
    name="status"
    control={control}
    rules={rules}
    render={({ field: { value, onChange } }) => {
      return (
        <SelectStatus
          error={error}
          style={{ width: 352 }}
          value={value}
          handle={onChange}
        />
      );
    }}
  />
);

import React, { useMemo } from "react";
import { AccordionAlende } from "./AccordionAlende";
import { formatDate, getMonthAgo } from "../../utils/formats";

export const Loan = ({
  onChange,
  expanded,
  id,
  amount,
  opening_date,
  term,
  due_date,
  monthly_instalment,
  status,
  principal_paid,
  interest_paid,
  current_value_of_tokens,
  principal_pending,
  tokens_provided,
  interest_pending,
  exchange_rate_at_opening_date,
  payment_progress,
  token,
}) => {
  const summary = useMemo(
    () => (
      <div className="loan-summary">
        {`Loan #${id}`}
        <span className="loan-summary__amount">{amount}</span>
        {`${getMonthAgo(opening_date)} mo.`}
        <span className={`loan-chip loan-chip--${status}`}>{status}</span>
      </div>
    ),
    [] /* eslint react-hooks/exhaustive-deps:0 */
  );

  const details = useMemo(
    () => (
      <>
        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Loan Amount</span>
            <span>{amount}</span>
          </div>
          <div className="loan-details__value-pair">
            <span>Opening Date</span>
            <span>{formatDate(opening_date, "/")}</span>
          </div>
        </div>

        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Loan Term</span>
            <span>{term ? `${term} Months` : ""}</span>
          </div>
          <div className="loan-details__value-pair">
            <span>Due Date</span>
            <span>{formatDate(due_date, "/")}</span>
          </div>
        </div>

        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Monthly Installment</span>
            <span>{monthly_instalment}</span>
          </div>
          <div className="loan-details__value-pair">
            <span>Loan Status</span>
            <span className={`loan-status--${status}`}>{status}</span>
          </div>
        </div>

        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Principal Paid</span>
            <span>{principal_paid}</span>
          </div>
          <div className="loan-details__value-pair">
            <span>Token Type</span>
            <span>{token?.name}</span>
          </div>
        </div>

        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Interest Paid</span>
            <span>{interest_paid}</span>
          </div>
          <div className="loan-details__value-pair">
            <span>Current Value of Tokens</span>
            <span>{current_value_of_tokens}</span>
          </div>
        </div>

        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Principal Pending</span>
            <span>{principal_pending}</span>
          </div>
          <div className="loan-details__value-pair">
            <span>Tokens Provided</span>
            <span>{tokens_provided}</span>
          </div>
        </div>

        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Interest Pending</span>
            <span>{interest_pending}</span>
          </div>
          <div className="loan-details__value-pair">
            <span>Exchange Rate at Opening Date</span>
            <span>{exchange_rate_at_opening_date}</span>
          </div>
        </div>

        <div className="loan-details__row">
          <div className="loan-details__value-pair">
            <span>Loan Payment Progress</span>
            <span>{payment_progress}%</span>
          </div>
          <div className="loan-details__value-pair"></div>
        </div>
      </>
    ),
    [] /* eslint react-hooks/exhaustive-deps:0 */
  );

  return (
    <AccordionAlende
      summary={summary}
      details={details}
      onChange={onChange}
      expanded={expanded}
    />
  );
};

import React from "react";
import { Controller } from "react-hook-form";
import { TextField, Autocomplete, Box } from "@mui/material";
import { countriesArray } from "../../utils/countries";
import { CircleFlag } from "react-circle-flags";
import { StyledEngineProvider } from "@mui/material/styles";
import styles from "../../styles/controls.module.css";

export const SelectCountry = ({
  style,
  handle,
  value,
  error,
  label = "Select country",
}) => {
  const renderOption = (props, option) => (
    <Box
      value={option.code}
      component="li"
      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
      {...props}
    >
      <CircleFlag
        countryCode={option.code.toLowerCase()}
        height="24"
        width="24"
      />
      <span className={styles["input__select-item"]}>{option.name}</span>
    </Box>
  );

  const renderInput = (params) => {
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <TextField
          {...params}
          name="country"
          error={error}
          variant="filled"
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />

        {value && (
          <CircleFlag
            countryCode={value.code.toLowerCase()}
            height="24"
            style={{ position: "absolute", right: 60, top: 20 }}
          />
        )}
      </div>
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <Autocomplete
        disablePortal
        isOptionEqualToValue={isOptionEqualToValue}
        blurOnSelect={true}
        className={styles.input}
        style={style || { width: "100%", marginBottom: 20 }}
        onChange={(e, val) => handle(val)}
        value={value}
        options={countriesArray}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={renderOption}
        renderInput={renderInput}
      />
    </StyledEngineProvider>
  );
};

export const ControlledSelectCountry = (
  { control, error, rules = {} },
  label
) => (
  <Controller
    name="country"
    label={label}
    control={control}
    rules={rules}
    render={({ field: { value, onChange } }) => {
      return (
        <SelectCountry
          error={error}
          style={{ width: 352 }}
          value={value}
          handle={onChange}
        />
      );
    }}
  />
);

const isOptionEqualToValue = (option, value) =>
  option.name === value.name && option.code === value.code;

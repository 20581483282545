import * as React from "react";
import { IconButton } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import style from "../../styles/controls.module.css";

export const ButtonClose = ({ handle }) => {
  return (
    <StyledEngineProvider injectFirst>
      <IconButton className={style["close-btn"]} onClick={handle} />
    </StyledEngineProvider>
  );
};

import "../../styles/App.css";

export const ExchangeRate = ({ value }) => (
  <div className="exchange-rate">
    <header className="exchange-rate__header">
      <b>{value?.code || ""}</b> Current Exchange Rate
    </header>
    <main className="exchange-rate__amount">{value?.rate || ". . ."}</main>
  </div>
);

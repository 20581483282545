import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DataService } from "../services/DataService";
import { useQueryClient } from "@tanstack/react-query";
import { Drawer } from "@mui/material";
import {
  ButtonMain,
  ButtonClose,
  CountryFilter,
  ControlledInputAlende,
  ButtonFilter,
} from "./UI";
import "../styles/App.css";

const defaultValues = {
  amount_from: "",
  amount_to: "",
  active_from: "",
  active_to: "",
  total_from: "",
  total_to: "",
  countries: [],
  last_name: "",
};

export const Filters = () => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues,
  });

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const onSubmit = (data) => {
    DataService.setSelection({
      ...data,
      countries: data.countries.map((item) => item.code),
    });
    queryClient.invalidateQueries({ queryKey: ["users"] });
  };

  const onReset = () => {
    reset();
    DataService.setSelection(defaultValues);
    queryClient.invalidateQueries({ queryKey: ["users"] });
  };

  return (
    <>
      <ButtonFilter
        handle={open}
        value={Object.values(dirtyFields).length}
        onClear={onReset}
      />

      <Drawer
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
        open={isOpen}
        onClose={close}
      >
        <form className="filters">
          <header className="filters__header">
            <h2 className="filters__label">Filter</h2>
            <ButtonClose handle={close} />
          </header>

          <main>
            <label className="filters__label">Last Name</label>
            <div className="filters__group">
              <ControlledInputAlende
                width={352}
                control={control}
                fieldName={"last_name"}
                label="Last Name"
                error={!!errors["Last Name"]}
              />
            </div>

            <label className="filters__label">Loan Amount</label>
            <div className="filters__group">
              <ControlledInputAlende
                type="number"
                control={control}
                fieldName={"amount_from"}
                label="From"
                rules={{ pattern: /^[0-9]*$/ }}
                error={!!errors["amount_from"]}
                postfix="€"
              />

              <ControlledInputAlende
                type="number"
                control={control}
                fieldName={"amount_to"}
                label="To"
                error={!!errors["amount_to"]}
                postfix="€"
              />
            </div>

            <label className="filters__label">Quantity of Active Loans</label>
            <div className="filters__group">
              <ControlledInputAlende
                type="number"
                control={control}
                fieldName={"active_from"}
                label="From"
                error={!!errors["active_from"]}
              />

              <ControlledInputAlende
                type="number"
                control={control}
                fieldName={"active_to"}
                label="To"
                error={!!errors["active_to"]}
              />
            </div>

            <label className="filters__label">Quantity of Total Loans</label>
            <div className="filters__group">
              <ControlledInputAlende
                type="number"
                control={control}
                fieldName={"total_from"}
                label="From"
                error={!!errors["total_from"]}
              />
              <ControlledInputAlende
                type="number"
                control={control}
                fieldName={"total_to"}
                label="To"
                error={!!errors["total_to"]}
              />
            </div>

            <label className="filters__label">Country</label>
            <Controller
              name="countries"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CountryFilter handle={onChange} countriesValue={value} />
              )}
            />
          </main>

          <div className="filters__buttons-block">
            <ButtonMain
              title="Reset Filter"
              width={352}
              handle={onReset}
              variant="empty"
              disabled={!isDirty}
            />
            <ButtonMain
              title="Apply"
              width={352}
              handle={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </Drawer>
    </>
  );
};

import { useState } from "react";
import { useForm } from "react-hook-form";
import { AuthService } from "../services/AuthService";
import { ButtonMain, ControlledInputAlende } from "./UI";
import logo from "../static/logo-alende.svg";

export const Login = () => {
  const [processing, setProcessing] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: process.env.REACT_APP_API_ADMIN_EMAIL || "",
      password: process.env.REACT_APP_API_ADMIN_PASSWORD || "",
    },
  });

  const submit = async (data) => {
    setProcessing(true);
    try {
      await AuthService.login(data);
    } catch (error) {
      alert(`Something went wrong: ${error.response?.data?.message}`);
    }
    setProcessing(false);
  };

  return (
    <div className="login-container">
      <img className="login-logo" src={logo} alt="Alende logo" />

      <div className="login-inputs">
        <ControlledInputAlende
          width={300}
          fieldName="email"
          label={`Email ${!!errors["email"] ? "must be valid value" : ""}`}
          control={control}
          error={!!errors["email"]}
          rules={{
            required: true,
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
          }}
        />

        <ControlledInputAlende
          width={300}
          fieldName="password"
          type="password"
          label={`Password ${!!errors["password"] ? "required" : ""}`}
          control={control}
          error={!!errors["password"]}
          rules={{ required: true }}
        />
      </div>

      <ButtonMain
        title="LOGIN"
        handle={handleSubmit(submit)}
        loading={processing}
        disabled={!!Object.keys(errors).length}
      />
    </div>
  );
};

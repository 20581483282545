import { useState } from "react";
import { Controller } from "react-hook-form";
import parsePhoneNumber from "libphonenumber-js";
import {
  FilledInput,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import style from "../../styles/controls.module.css";

export const InputAlende = ({
  label,
  value = "",
  handle,
  name = "",
  type = "text",
  postfix = null,
  width = 168,
  disabled = false,
  error = false,
  ...rest
}) => {
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);

  const formatIncome = () => {
    /*eslint eqeqeq:0*/
    if (type === "number" && value != 0) {
      value = (+value).toLocaleString("en-US");
    }

    if (type === "tel") {
      const phoneNumber = parsePhoneNumber(`+${value}`);
      const international = phoneNumber?.formatInternational();
      value = international || value;

      if (phoneNumber && !phoneNumber.isValid()) {
        !isInvalidPhone && setIsInvalidPhone(true);
      } else {
        isInvalidPhone && setIsInvalidPhone(false);
      }
    }

    return value;
  };

  const formatOutcome = ({ target }) => {
    let newValue = target.value;

    if (type === "number" && newValue !== "") {
      const num = parseFloat(newValue.replace(/,/g, ""));
      if (isNaN(num)) return value;
      newValue = num;
    }

    if (type === "tel" && newValue !== "") {
      const clean = newValue.replaceAll(/\s/g, "");
      const num = +clean;
      if (isNaN(num) && newValue !== '') return value;
      newValue = num;
    }

    return newValue;
  };

  return (
    <FormControl
      className={`${style.input} ${
        isInvalidPhone ? style["input--wrong-phone"] : ""
      }`}
      variant="filled"
      style={{ width }}
    >
      <InputLabel>{label}</InputLabel>
      <FilledInput
        autoComplete="off"
        error={error}
        type={type === "password" ? "password" : "text"}
        style={{ width }}
        value={formatIncome()}
        onChange={(e) => handle(formatOutcome(e))}
        name={name}
        disabled={disabled}
        endAdornment={
          postfix ? (
            <InputAdornment position="end">{postfix}</InputAdornment>
          ) : null
        }
        {...rest}
      />
    </FormControl>
  );
};

export const ControlledInputAlende = ({
  fieldName,
  label,
  control,
  error = false,
  rules = {},
  postfix = null,
  width = 168,
  type = "text",
}) => (
  <Controller
    name={fieldName}
    control={control}
    rules={rules}
    render={({ field: { ref, name, value, onChange, onBlur } }) => {
      return (
        <InputAlende
          error={error}
          width={width}
          label={label}
          name={name}
          value={value}
          handle={onChange}
          onBlur={onBlur}
          inputRef={ref}
          postfix={postfix}
          type={type}
        />
      );
    }}
  />
);

const getLocalAccessToken = () => {
  const token = localStorage.getItem("token");
  if (!token || token === 'undefined') {
    return false
  }

  return JSON.parse(token);
};

const setLocalAccessToken = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};

const removeLocalAccessToken = () => {
  localStorage.removeItem("token");
};

const TokenService = {
  getLocalAccessToken,
  setLocalAccessToken,
  removeLocalAccessToken,
};

export default TokenService;

import api from "./api";

let selection = { page: 1 };
const setSelection = (val) => {
  selection = { ...selection, ...val };
  for (let key in selection) {
    if (!selection[key]) delete selection[key];
  }
  return selection;
};

const downloadUsers = async () => {
  const file = await api.get("/api/v1/admin/users/export", {
    responseType: "blob",
  });

  const url = window.URL.createObjectURL(new Blob([file.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "users.xlsx");
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

const getAllUsers = async () => {
  return await api.get("api/v1/admin/users", {
    params: selection,
  });
};

const uploadFile = async (data) => {
  return await api.post("api/v1/admin/users/import", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const addNewUser = async (data) => {
  return await api.post("api/v1/admin/users", data);
};

const editUser = async (data) => {
  const { id, ...query } = data;
  return await api.put(`/api/v1/admin/users/${id}`, { ...query });
};

const getRates = async () => {
  return await api.get("api/v1/app/tokens");
};

export const DataService = {
  getRates,
  editUser,
  addNewUser,
  uploadFile,
  getAllUsers,
  downloadUsers,
  setSelection,
};

import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { SvgIcon } from "@mui/material";
import style from "../../styles/controls.module.css";

export const AccordionAlende = ({ summary, details, expanded, onChange }) => {
  const icon = (
    <SvgIcon
      viewBox="0 0 40 40"
      style={{
        width: 40,
        height: 40,
      }}
    >
      {expanded ? (
        <>
          <circle cx="20" cy="20" r="20" fill="#00AC99" fillOpacity="0.08" />
          <rect x="12" y="18.5" width="16" height="3" rx="1.5" fill="#00AC99" />
        </>
      ) : (
        <>
          <circle cx="20" cy="20" r="20" fill="#364140" fillOpacity="0.08" />
          <path
            d="M26.5434 18.4939H21.5061V13.4568C21.5061 11.5144 18.4937 11.5144 18.4937 13.4568V18.4939H13.4564C11.5145 18.4939 11.5145 21.5061 13.4564 21.5061H18.4937V26.5432C18.4937 28.4856 21.5061 28.4856 21.5061 26.5432V21.5061H26.5434C28.4855 21.5061 28.4855 18.4939 26.5434 18.4939Z"
            fill="#364140"
            fillOpacity="0.32"
          />
        </>
      )}
    </SvgIcon>
  );

  return (
    <div
      className={`${style.accordion} ${
        expanded ? style["accordion--active"] : ""
      }`}
    >
      <Accordion expanded={expanded} onChange={onChange}>
        <AccordionSummary
          expandIcon={icon}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    </div>
  );
};

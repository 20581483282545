import React, { useState } from "react";
import { useForm } from "react-hook-form";
import parsePhoneNumber from "libphonenumber-js";
import { DataService } from "../services/DataService";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Drawer } from "@mui/material";
import {
  ButtonClose,
  Loan,
  ExchangeRate,
  ButtonMain,
  ControlledInputAlende,
  ControlledSelectCountry,
  LoaderFullSize,
} from "./UI";
import { formatDate } from "../utils/formats";
import "../styles/App.css";

export const UserDetails = ({ open = true, onClose, user }) => {
  const queryClient = useQueryClient();
  const [expanded, setExpanded] = useState(null);

  const { data: rates, isLoading } = useQuery({
    queryKey: ["rates"],
    queryFn: DataService.getRates,
  });

  const mutation = useMutation({
    mutationFn: DataService.editUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      first_name: user?.first_name || "",
      country: user?.country || null,
      last_name: user?.last_name || "",
      email: user?.email || "",
      middle_name: user?.middle_name || "",
      phone_number: user?.phone_number || "",
    },
  });

  const cancel = () => reset();

  const onSubmit = (data) => {
    mutation.mutate({
      ...data,
      country: data.country?.code,
      phone_number: parsePhoneNumber(`+${data.phone_number}`).formatInternational(),
      status: user.status,
      id: user.id,
    });
    reset(data);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      ModalProps={{ keepMounted: false }}
    >
      <form className="user-details">
        <header className="user-details__header">
          <h2 className="user-details__title">Client # {user?.id}</h2>
          <span className="user-details__subtitle">
            Client Since: <b>{formatDate(user?.client_since)}</b>
          </span>
          <ButtonClose handle={onClose} />
        </header>

        <main>
          <div className="user-details__row">
            <ControlledInputAlende
              width={352}
              fieldName="first_name"
              label={`First Name ${!!errors["first_name"] ? "required" : ""}`}
              control={control}
              error={!!errors["first_name"]}
              rules={{ required: true }}
            />

            <ControlledSelectCountry
              fieldName={"country"}
              control={control}
              rules={{ required: true }}
              error={!!errors["country"]}
            />
          </div>

          <div className="user-details__row">
            <ControlledInputAlende
              width={352}
              fieldName="last_name"
              label={`Last Name ${!!errors["last_name"] ? "required" : ""}`}
              control={control}
              error={!!errors["last_name"]}
              rules={{ required: true }}
            />

            <ControlledInputAlende
              width={352}
              fieldName="email"
              label={`Email ${!!errors["email"] ? "must be valid value" : ""}`}
              control={control}
              error={!!errors["email"]}
              rules={{
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              }}
            />
          </div>

          <div className="user-details__row">
            <ControlledInputAlende
              width={352}
              fieldName="middle_name"
              label="Middle Name"
              control={control}
              error={!!errors["middle_name"]}
            />
            <ControlledInputAlende
              width={352}
              type="tel"
              fieldName="phone_number"
              label="Phone Number"
              control={control}
            />
          </div>

          {isDirty && (
            <div className="user-details__buttons-block">
              <ButtonMain
                title="Cancel"
                variant="filled-gray"
                small
                width={128}
                handle={cancel}
              />
              <ButtonMain
                title="Apply"
                small
                width={128}
                disabled={!!Object.keys(errors).length}
                handle={handleSubmit(onSubmit)}
              />
            </div>
          )}

          <h3 className="user-details__title">Current Amount</h3>
          <div className="user-details__row">
            <ExchangeRate value={rates?.data[0]} />
            <ExchangeRate value={rates?.data[1]} />
          </div>

          <div>
            <h3 className="user-details__title">
              {user?.loans.length ? "Loans" : "No Loans"}
            </h3>
            {user?.loans.map((loan) => (
              <Loan
                key={loan.id}
                expanded={expanded === loan.id}
                onChange={() =>
                  setExpanded(expanded === loan.id ? null : loan.id)
                }
                {...loan}
              />
            ))}
          </div>
        </main>

        {(isLoading || mutation.isLoading) && <LoaderFullSize />}
      </form>
    </Drawer>
  );
};

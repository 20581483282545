import axios from "axios";
import { AuthService } from "./AuthService";
import TokenService from "./TokenService";
axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (!token) return config;

    config.headers["x-access-token"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const onError = (err) => {
  const status = err?.response?.status;
  if (status === 401 || status === 419) {
    AuthService.logout();
  }
  return Promise.reject(err);
};

instance.interceptors.response.use((res) => res, onError);

export default instance;

import * as React from "react";
import Button from "@mui/material/Button";
import { StyledEngineProvider } from "@mui/material/styles";
import style from "../../styles/controls.module.css";

export const ButtonLogout = ({ handle }) => (
  <StyledEngineProvider injectFirst>
    <Button className={style["logout-btn"]} onClick={handle}>
      LOG OUT
    </Button>
  </StyledEngineProvider>
);

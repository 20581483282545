import React from "react";
import { useForm } from "react-hook-form";
import { Drawer } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DataService } from "../services/DataService";
import {
  ButtonClose,
  ButtonMain,
  ControlledInputAlende,
  ControlledSelectCountry,
  ControlledSelectStatus,
  LoaderFullSize,
} from "./UI";
import { formatDate } from "../utils/formats";
import "../styles/App.css";

export const NewUserDetails = ({ open, onClose }) => {
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: Object.fromEntries(
      formData.map(({ fieldName, defaultValue }) => [fieldName, defaultValue])
    ),
  });

  const mutation = useMutation({
    mutationFn: DataService.addNewUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      cancel();
    },
  });

  const onSubmit = (data) => {
    mutation.mutate({
      ...data,
      country: data.country.code,
      phone_number: String(data.phone_number),
    });
  };

  const cancel = () => {
    reset();
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={cancel}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <form className="user-details user-details--new">
        <header className="user-details__header user-details__header--new">
          <h2 className="user-details__title">New Client</h2>
          <ButtonClose handle={cancel} />
        </header>

        <main>
          {formData.map(({ fieldName, label, rules, message, type }) => (
            <div
              className="user-details__row user-details__row--single"
              key={`newUserField-${fieldName}`}
            >
              {fieldName === "country" && (
                <ControlledSelectCountry
                  fieldName={"country"}
                  control={control}
                  rules={rules}
                  error={!!errors[fieldName]}
                />
              )}

              {fieldName !== "status" &&
                fieldName !== "country" &&
                fieldName !== "client_since" && (
                  <ControlledInputAlende
                    width={352}
                    type={type}
                    control={control}
                    fieldName={fieldName}
                    label={`${label} ${!!errors[fieldName] ? message : ""}`}
                    rules={rules}
                    error={!!errors[fieldName]}
                  />
                )}

              {fieldName === "status" && (
                <ControlledSelectStatus
                  control={control}
                  fieldName={fieldName}
                  rules={rules}
                  error={!!errors[fieldName]}
                />
              )}
            </div>
          ))}
        </main>

        <div className="user-details__buttons-block--new">
          <ButtonMain
            title="Cancel"
            variant="filled-gray"
            small
            width={128}
            handle={cancel}
          />
          <ButtonMain
            title="Apply"
            small
            width={128}
            disabled={!!Object.keys(errors).length}
            handle={handleSubmit(onSubmit)}
          />
        </div>

        {mutation.isLoading && <LoaderFullSize />}
      </form>
    </Drawer>
  );
};

const formData = [
  {
    fieldName: "first_name",
    label: "First Name",
    rules: { required: true },
    defaultValue: "",
    message: "required",
  },
  {
    fieldName: "last_name",
    label: "Last Name",
    rules: { required: true },
    defaultValue: "",
    message: "required",
  },
  {
    fieldName: "middle_name",
    label: "Middle Name",
    // rules: { required: true },
    defaultValue: "",
    message: "",
  },
  {
    fieldName: "email",
    label: "Email",
    rules: { required: true, pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/ },
    defaultValue: "",
    message: "must be valid value",
  },
  {
    fieldName: "country",
    label: "Country",
    rules: { required: true },
    defaultValue: null,
    message: "required",
  },
  {
    fieldName: "phone_number",
    label: "Phone Number",
    defaultValue: "",
    message: "must be number",
    type: "tel",
    rules: { pattern: /^\d+$/ },
  },
  {
    fieldName: "status",
    label: "Status",
    rules: { required: true },
    defaultValue: null,
    message: "required",
  },
  {
    fieldName: "client_since",
    defaultValue: formatDate(new Date()),
  },
];
